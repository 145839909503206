import Vue from 'vue'
import Router from 'vue-router'
import index from "@/page/match/match_list"
import download from "@/page/match/download.vue"
import msg from "@/page/match/msg.vue"

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: index,
            meta:{
                title:'偶遇一个APP，感觉很不错，真实、安全和高效，分享给您。'
            }
        },
        {
            path: '/index',
            name: 'index',
            component: index,
            meta:{
                title:'偶遇一个APP，感觉很不错，真实、安全和高效，分享给您。。'
            }
        },
        {
            path: '/download',
            name: 'download',
            component: download,
            meta:{
                title:'偶遇一个APP，感觉很不错，真实、安全和高效，分享给您。'
            }
        },
        {
            path: '/msg',
            name: 'msg',
            component: msg,
            meta:{
                title:'倾心之灵IOS的APP'
            }
        }
        
    ]
})
