<template>
    <div>
        <div class="weixin-tip" v-if="isWeixin==1" @click="isWeixin=0">
            <p style="margin-left:4rem;margin-top:10%"><img style="margin-left:1rem" src="../../static/arrows.png"/></p>
            <p style="margin-right:1rem">第一步，点击右上角“···”</p>
            <p style="margin-top:10%;margin-right:1rem">
                <span>第二步，选择在 </span>
                <img v-if="isIos!=1" src="../../static/browser_wechat.png"/>
                <img v-if="isIos==1" src="../../static/browser_wechat.png"/>
                <span v-if="isIos!=1"> 浏览器打开</span>
                <span v-if="isIos==1"> 默认浏览器打开</span>
            </p>
            <div v-if="!isIos">
                <p style="margin-top:10%;margin: center">第三步，打开后，再次点击下方“立即下载”</p>
                <p style="margin-top:10%;margin-left: 4rem"><img src="../../static/down.png"/></p>
            </div>
        </div>
        <div>
            <div class="top">
                <div class="header">
                <div style="margin-bottom: 1.9rem;"><span>&nbsp;</span></div>
                <div id="downloadButton" @click="download_app()" class="download_btn">
                    <img :style="{ 'margin-top': heightVar }" v-if="isIos" class="download_icon" src="../../static/download.png">
                    <img :style="{ 'margin-top': heightVar }" v-if="!isIos" class="download_icon" src="../../static/download.png">
                </div>
                </div>
           
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import Vue from 'vue'
import axios from 'axios'
export default {
    data() {
        return {
            isWeixin: 0,
            isIos: 0,
            channel: '',
            platform: 1,
            dialog_visible: false,
            uid: '',
            app_id: '',
            screenWidth: 0,
            screenHeight: 0,
            heightVar: "6rem",
            invite_code: "",
            button_content: "立即下载",
            api_url:'http://register.beibao.net.cn/prod-api/',
            from: ''
        }
    },
    created() {
        if (this.$route.query.channel) {this.channel = this.$route.query.channel}
        this.from = this.$route.query.from
        this.app_id = this.$route.query.app_id
        this.uid = this.$route.query.uid
        if (this.uid) {this.channel = this.uid}
        var u = navigator.userAgent;
        this.invite_code = this.$route.query.invite_code
        this.isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if(this.from == 1){
            this.platform=1
            this.channel='android'
        }else if(this.from == 2){
            this.platform=2
            this.channel='ios'
        }else{
            this.platform=3
            this.channel='guanwang'
        }
        if (isIos) {
            this.platform = 2
            this.channel='ios'
        } 

        //判断是否百度来源
        var ref = document.referrer
        if (ref.search("baidu") != -1) {
            this.channel = 'baidu'
        }
        let params={
          'action': 'open_web',
          'channel':this.channel,
          'inviteCode':this.invite_code,
          'platform':this.platform,
          'type':'1'
        }
      let url = this.api_url + "/gateway/invite/operationRecord.htm"
      
      axios.post(url, params,{
        headers:{
          'Content-Type':'application/json',
          'client-info':'web',
          'auth-token':'123456'
        }
      }).then(function (response) {
        console.log(response);
      }).catch(function (error) {
        console.log(error);
      });
        var ua = navigator.userAgent.toLowerCase();
        var is_wechat = ua.indexOf('micromessenger') != -1;
      
    },
    methods: {
        download_app(){
                
            //判断是否百度
            var ref = document.referrer
           if (ref.search("baidu") != -1) {
            this.channel = 'baidu'
            }
            let params={
            'action': 'click_download',
            'channel':this.channel,
            'inviteCode':this.invite_code,
            'platform':this.platform,
            'type':'1'
            }
            let url = this.api_url + "/gateway/invite/operationRecord.htm"
        
            axios.post(url, params,{
                headers:{
                'Content-Type':'application/json',
                'client-info':'web',
                'auth-token':'123456'
                }
            }).then(function (response) {
                console.log(response);
            }).catch(function (error) {
                console.log(error);
            });
            if (!this.uid) {
                var ua = navigator.userAgent.toLowerCase();
                this.isWeixin = ua.indexOf('micromessenger') != -1;
                if (this.isIos) {
                    if (this.isWeixin) {
                        return
                    } else {
                        window.location.href = ("http://register.beibao.net.cn/msg")
                    }
                } else{
                    window.location.href="http://ms3.beibao.net.cn/s/qingxinjyq/qingxinzhiling.apk"
                }
            }
        }
    }
}
</script>

<style>
.top{
    width: 100%;
    height: 100%;
    background:url("../../static/down_image_bg.png");
    background-size: cover;
    position: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.img_circle{
    width: 80%;
}
.download_icon{
    width: 80%;
}
.download_btn{
    margin: left 15% ;
    position: absolute;
    bottom:2%;
}
.top .download{
    margin-left: 30%;
    width: 40%;
    height: 0.9rem;
    text-align: center;
    line-height: 0.8rem;
    color: #ffffff;
    font-size: 0.32rem;
    border-radius: 0.15rem;
    border:0.04rem solid #FFFFFF;
}
.top .content_top{
    margin-top: 0.4rem;
    color: #FFFFFF;
    font-size: 0.32rem;
}
.top .center_line{
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    color: #FFFFFF;
    font-size: 0.32rem;
    color: #FE5703;
}
.top .header {
  flex: 1;
}

.belt{
    margin-top: 0.7rem;
    margin-bottom: 0.6rem;
    width: 100%;
    height: 0.02rem;
    background-color: #aaaaaa;
}
.weixin-tip{
    position: fixed; 
    left:0; 
    top:0; 
    bottom:0; 
    background: rgba(0,0,0,0.8); 
    filter: alpha(opacity=80);  
    height: 100%; 
    width: 100%; 
    z-index: 100;
}
.weixin-tip p{
    text-align: center; 
    padding:0 5%;
    color: #f1f1f1;
    font-size: 0.35rem;
}
</style>

